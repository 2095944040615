<template>
<div class="container-fluid p-0">
    <headerDesign name="Adretreaver Products"
                  logo="https://adretreaver.com/wp-content/uploads/2021/03/AdRlogo-500.png"
                  button-show="false"
                  button-name="Client Management"
                  class=""></headerDesign>
    <div class="row" style="background-color: #E8E8E8;">
        <div class="col-4 col-md-4 p-3 d-flex justify-content-center">
            <a href="" to="/clients" @click="$router.push({ path: '/clients' })" class="font-weight-bolder text-decoration-none" style="font-size: 17px" v-text="'ALL CLIENTS'"/>
        </div>
        <div class="col-4 col-md-4 p-3 d-flex justify-content-center">
            <a href="" to="/clients" @click="$router.push({ path: '/clients', query: { edit: '1', id: $route.query.client_id } })" class="font-weight-bolder text-decoration-none" style="font-size: 17px" v-text="'CLIENT MANAGEMENT'"/>
        </div>
        <div class="col-4 col-md-4 p-3 d-flex justify-content-center">
            <a href="" to="/clients" @click="$router.push({ path: '/product/management', query: { client_id: $route.query.client_id } })" class="font-weight-bolder text-decoration-none" style="font-size: 17px" v-text="'PRODUCT MANAGEMENT'"/>
        </div>
    </div>
    <manage v-if="manageModal" :id="$route.query.client_id" v-on:closedm="manageModal = false" />
    <div class="row  ml-5 p-5 " >

        <div class="col-2">
            <div class="sidebar">
                <a class="active font-weight-bolder" href="#home">PRODUCT OPTIONS</a>

                <div v-for="prod in products" :key="prod._id">
                    <b-button class=" btn-secondary btn-sm mt-2 mb-2 btn1 " block v-b-toggle="prod._id" variant="light"
                              style="background-color: white !important; border-bottom-color: #cccbc6 !important;" @click="siftPlans(prod._id); selProdInfo.id = prod._id; selProdInfo.identifier = prod.Identifier" >
                        {{ prod.Name }} <i class="fa fa-caret-down"></i></b-button>
                    <b-collapse v-if="prod.Identifier =='adverifier'" :id="prod._id" class="mt-2">
                        <ul>
                            <li v-for="(s, index) in avsubscriptions"
                                :key="s.id"
                                @click="setActive2(index, s.name); siftPlanType(s.value)">
                                <a :class="{ 'active': activeIndex_av === index}" style="cursor: pointer;">
                                    {{ s.name }}</a>
                            </li>
                        </ul>
                    </b-collapse>
                    <b-collapse v-if="prod.Identifier =='emailverifier'" :id="prod._id" class="mt-2" >

                        <ul>
                            <li v-for="(s, index) in subscriptions"
                                :key="s.id"
                                @click="setActive(index,s.name); siftPlanType(s.value)">
                                <a  :class="{ 'active': activeIndex === index}" >
                                    {{ s.name }}</a>

                            </li>
                        </ul>


                    </b-collapse>
                    <b-collapse v-if="prod.Identifier =='contentcatcher'" :id="prod._id" class="mt-2" >
                        <ul>
                            <li v-for="(s, index) in cssubscriptions" style="cursor: pointer"
                                :key="s.id"
                                @click="setActive3(index, s.name); siftPlanTypeCS(s.value)">
                                <a  :class="{ 'active': activeIndex_cs === index}" >{{ s.name }}</a>
                            </li>
                        </ul>
                    </b-collapse>
                </div>
            </div>
        </div>

        <div class="col-9">
            <div class="content ">
                <h6 class="text-bold pl-4" > CAMPAIGN SELECTION - {{selectedItem}}</h6>
                <hr>
                <div class="row pt-3 ml-1 ">
                    <div v-show="start" class="text-center container pt-5">
                        <h6 class="text-center mt-5"> SELECT PRODUCT </h6>
                    </div>
                    <div v-show="empty" class="text-center container pt-5">
                        <h6 class="text-center mt-5"> No Workflow created yet. Create one Now </h6>
                    </div>
<!--                <div v-show="emailvarifier" class="card col-3  ml-5 mr-0 mb-2 container" v-for="item in temp " :key="item.id" >-->
<!--                    <div class="card-body " >-->
<!--                        <div class=" d-flex justify-content-start">-->
<!--                            <h4 class="card-title mr-4 font-weight-bolder">{{ item.Initial }}</h4>-->
<!--                            <h6 class="card-subtitle mt-1  text-muted">{{item.Type}}</h6>-->
<!--                        </div>-->

<!--                        <p class="card-text">{{item.Discription}}</p>-->
<!--                        <h6 class="font-weight-bolder">Plan Size: {{item.planSize}} </h6>-->
<!--                        <h6 class="font-weight-bolder">Plan Cost: {{item.Cost}} </h6>-->

<!--                        <div class="d-flex justify-content-end">-->
<!--                            <a href="#" class="card-link font-weight-bolder   ">Select</a>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

                    <div v-show="adverifier || emailvarifier" :v-if="(emailvarifier)? advarifier = false :advarifier = true " class="card col-3 ml-5 mr-0 mb-2 container" v-for="( ite, index ) in temp " :key="index" >
                        <div class="card-body" v-if="proxyType === 'data'">
                            <div class=" d-flex justify-content-start">
                                <h4 v-show="adverifier" class="card-title mr-4 font-weight-bolder">AV</h4>
                                <h4 v-show="emailvarifier" class="card-title mr-4 font-weight-bolder">EV</h4>
                                <h6 class="card-subtitle mt-1  text-muted">{{ite.name}}</h6>
                            </div>

                            <p class="card-text">Description </p>
                            <h6 class="font-weight-bolder">Plan Size: </h6>
                            <div class="form-group">
                                <label class="d-block" v-for="size in ite.ProxyType" :key="size._id">
                                    <b-form-radio type="checkbox" v-model="selPlan" class="mr-1" @select="planProxy = size.ProxyType" :value="size._id" name="plansize"> {{ size.Bandwidth | convertG }} </b-form-radio>
                                </label>
                            </div>

                            <div class="d-flex justify-content-end">
                                <a href="#" class="card-link font-weight-bolder" @click="selectedPlan">Select</a>
                            </div>
                        </div>
                        <div class="card-body" v-if="proxyType === 'click'">
                            <div class=" d-flex justify-content-start">
                                <h4 v-show="adverifier" class="card-title mr-4 font-weight-bolder">AV</h4>
                                <h4 v-show="emailvarifier" class="card-title mr-4 font-weight-bolder">EV</h4>
                                <h6 class="card-subtitle mt-1  text-muted">{{ite.name}}</h6>
                            </div>

                            <p class="card-text">{{ite.Discription}}</p>
                            <h6 class="font-weight-bolder">Plan Size: </h6>
                            <div class="form-group">
                                <label class="d-block" v-for="size in ite.ProxyType" :key="size._id">
                                    <b-form-radio type="checkbox" v-model="selPlan" class="mr-1" :value="size._id" name="plansize"> {{ size.NumberOfClicks | convert }} </b-form-radio>
                                </label>
                            </div>

                            <div class="d-flex justify-content-end">
                                <a href="#" class="card-link font-weight-bolder" @click="selectedPlan">Select</a>
                            </div>
                        </div>
                    </div>
                    <div v-show="contentshield " class="card col-5 ml-5 mr-0 mb-2 container" v-for="ite in csProduct " :key="ite._id" >
                        <div class="card-body">
                            <div class=" d-flex justify-content-start">
                                <h4 class="card-title mr-4 font-weight-bolder">CS</h4>
                                <h6 class="card-subtitle mt-1  text-muted">{{ite.name}}</h6>
                            </div>

                            <p class="card-text"><strong>Minimum Passing Score:</strong> {{ite.minScore}} </p>
                            <p class="card-text"><strong>Maximum Passing Score:</strong> {{ite.maxScore}} </p>
                            <h6 class="font-weight-bolder pt-3">Cost: ${{ ite.price }}</h6>

                            <div class="d-flex justify-content-end">
                                <a href="#" class="card-link font-weight-bolder" @click="selectedCSPlan(ite._id)">Select</a>
                            </div>
                        </div>
                        <div :v-if="(ite._id) ? empty = false : empty = true"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-1">
            <div class=" " style=" height: 37rem;" >
            </div>
        </div>

    </div>
    <footerDesign  first-button="Add a Product" first-button-show="dark"  second-button="Manage Domains"
                   second-button-show="false" third-button-show="false" third-button="Manage Domains" class="mt-4">
    </footerDesign>
</div>
</template>

<script>
import footerDesign from "@/components/style_Component/footerDesign";
import headerDesign from "@/components/style_Component/headerDesign";
import Manage from "@/components/domain/management/manage";
import axios from "axios";
import swal from "sweetalert";
import Swal from "sweetalert2";

export default {
    name: "adProducts",
    components: {
        Manage,
        footerDesign,
        headerDesign
    },
    data(){
        return{
            adverifier:false,
            emailvarifier:false,
            contentshield: false,
            selectedItem: '',
            subscriptions: [{id:2,name:'Click Usage', value:'click'},{id:3,name:'Add Ons'}],//ev
            cssubscriptions: [{id:1,name:'Brand Search', value:'brand'},{id:2,name:'Photo Search', value:'photo'},{id:2,name:'Likeness Search', value:'likeness'},{id:2,name:'Video Search', value:'video'}],//cs
            avsubscriptions: [{id:2,name:'Click Usage', value:'click' },{id:3,name:'Add Ons'}],//av
            activeIndex: undefined,//ev
            activeIndex_av: undefined,//av
            activeIndex_cs: undefined,//cs
            start:true,
            empty: false,
            manageModal: false,
            selectedPlans: [],
            filteredPlans: [],
            csProduct: [],
            selProdInfo: {
                id: '',
                identifier: ''
            },
            planProxy: '',
            selPlan: '',
            products: '',
            proxyType: '',
            plans: '',
            tempp: [],

        }
    },
    created() {
        this.$root.preloader = false;
        this.loadPlans()
        this.loadProducts()
    },
    computed: {
        temp() {
            let data = this.filteredPlans.reduce(( acc, value ) => {
                if ( !acc[value.ProxyType] ) {
                    acc[value.ProxyType] = { name: value.ProxyType }
                    acc[value.ProxyType].ProxyType = [value]
                } else {
                    acc[value.ProxyType].ProxyType.push(value)
                }
                return acc
            }, {})
            return Object.values(data)
        },
    },
    filters: {
        convert: function(value) {
            if (value < 1000000) {
                return value / 1000 + 'K'
            } else {
                return value / 1000000 + 'M'
            }
        },
        convertG: function(value) {
            return value / 1000000000 + 'G'
        },
    },
    methods:{
        advi() {
            this.start = false;
            this.activeIndex = 4 // remove the color for ev menu
            this.activeIndex_cs = 4
            if( this.emailvarifier === true){
                this.emailvarifier = false
            }
            if( this.contentshield === true){
                this.contentshield = false
            }
         this.adverifier = true
        },
        email() {
            this.start = false;
            this.activeIndex_av = 4
            this.activeIndex_cs = 4// remove the color for av menu
            if( this.adverifier === true){
                this.adverifier = false
            }
            if( this.contentshield === true){
                this.contentshield = false
            }
          this.emailvarifier = true
        },
        cs() {
            this.start = false;
            this.activeIndex_av = 4 // remove the color for av menu
            this.activeIndex = 4
            if( this.adverifier === true){
                this.adverifier = false
            }
            if( this.emailvarifier === true){
                this.emailvarifier = false
            }
            this.contentshield = true
        },
        setActive(index,name) {
            this.selectedItem = name
            this.activeIndex = index // set the color for ev menu
            if(index === 1 || index === 0) this.email()
        },
        setActive2(index,name) {
            this.selectedItem = name
            this.activeIndex_av = index // set the color for av menu
            if(index === 1 || index === 0) this.advi()
        },
        setActive3(index,name) {
            this.selectedItem = name
            this.activeIndex_cs = index // set the color for av menu
            if(index === 1 || index === 0) this.cs()
        },
        siftPlans(id) {
            this.selectedPlans = []
            this.plans.filter( (plan) => {
                if ( plan.ProductId === id ) {
                    this.selectedPlans.push(plan)
                }
            })
        },
        siftPlanTypeCS(planType) {
            this.csProduct = []
            this.selectedPlans.forEach((item) => {
                if ( planType === item.type ) {
                    this.csProduct.push(item)
                }
            })
        },
        siftPlanType(planType) {
            this.proxyType = planType
            this.filteredPlans = []
            this.selectedPlans.filter( ( plan ) => {
                if ( plan.Driven === planType ) {
                    this.filteredPlans.push( plan )
                }
            })
        },
        async loadProducts() {
            try {
                const data = await axios.get(`${this.$root.serverUrl}/admin/products/`);
                this.products = data.data.data;
            } catch(err){
                console.log(err);
                swal({title:"Error", text: err, icon: "error"});
            }
        },
        async selectedCSPlan(workflow_id) {
            swal({title:this.planProxy + " Plan", text:"Add plan to client Account?", icon:"info", buttons:['No','Yes']}).then(async function(val) {
                if (!val) return;

                const {value:name} = await Swal.fire({
                    input: 'text',
                    inputLabel: 'Desired name for product',
                    inputPlaceholder: 'Name',
                    showCancelButton: true,
                    inputValidator: (reason) => {
                        if (!reason) {
                            return 'Please fill in name!'
                        }
                    }
                })

                if(name) {

                    this.$root.preloader = true;

                    axios.put(`${this.$root.serverUrl}/admin/clients/plan`, {
                        client_id: this.$route.query.client_id,
                        name: name,
                        plan_id: workflow_id,
                        product_id: this.selProdInfo.id,
                        product_type: this.selProdInfo.identifier
                    }).then((resp) => {
                        //Process the results
                        this.$root.preloader = false;
                        if (resp.data && !resp.data.error) {
                            //Saved successfully, navigate to Plans List
                            this.$router.push({
                                path: '/product/management',
                                query: {client_id: this.$route.query.client_id}
                            })
                        } else {
                            //Handle errors
                            swal({
                                title: this.planProxy + " Plan",
                                text: "Unable to complete your request",
                                icon: "error"
                            });
                        }
                    }).catch(function (err) {
                        this.$root.preloader = false;
                        this.errormsg = err.message || "An unexpected error occured";
                        swal({title: "Error", text: this.errormsg, icon: "error"});
                    }.bind(this))
                }
            }.bind(this));

        },
        selectedPlan() {
            swal({title:this.planProxy + " Plan", text:"Add plan to client Account?", icon:"info", buttons:['No','Yes']}).then(async function(val) {
                if (!val) return;


                const {value:reason} = await Swal.fire({
                    input: 'text',
                    inputLabel: 'Desired name for product',
                    inputPlaceholder: 'Name',
                    showCancelButton: true,
                    inputValidator: (reason) => {
                        if (!reason) {
                            return 'Please fill in name!'
                        }
                    }
                })

                if(reason) {
                    this.$root.preloader = true;
                    axios.put(`${this.$root.serverUrl}/admin/clients/plan`, { client_id: this.$route.query.client_id, name: reason, plan_id: this.selPlan , product_id: this.selProdInfo.id,
                        product_type: this.selProdInfo.identifier} ).then(function (resp) {
                        //Process the results
                        if (resp.data.error) {
                            this.$root.preloader = false;
                            swal({title: 'Error', text: resp.data.error.message, icon: 'error'}).then(() => {
                            })
                        } else {
                            this.$root.preloader = false;
                            if (resp.data && !resp.data.error) {
                                //Saved successfully, navigate to Plans List
                                this.$router.push({
                                    path: '/product/management',
                                    query: {client_id: this.$route.query.client_id}
                                })

                                // swal({
                                //     title: this.planProxy + " Plan",
                                //     text: "Your selected Plan was Added.",
                                //     icon: "success"
                                // }).then(function () {
                                //
                                // }.bind(this));
                            } else {
                                //Handle errors
                                swal({
                                    title: this.planProxy + " Plan",
                                    text: "Unable to complete your request",
                                    icon: "error"
                                });
                            }
                        }
                }.bind(this))
                }

                }.bind(this))
                    .catch(function (err) {
                        this.$root.preloader = false;
                        this.errormsg = err.message || "An unexpected error occured";
                        swal({title: "Error", text: this.errormsg, icon: "error"});
                    }.bind(this))

        },

        loadPlans() {
            axios.get(`${this.$root.serverUrl}/admin/products/plans`).then((resp) => {
                //Store all plans
                this.plans = (resp.data && resp.data.data)? resp.data.data : [];
            });
        },
    }
}
</script>

<style scoped>


.btn1{
    text-align: left !important;
    padding-left: 15px;
}
.sidebar {
    margin: 0;
    padding: 0;
    /*width: 400px;*/
    background-color: white;
    /*position: fixed;*/
    height: 100%;
    overflow: auto;
}

ul {
    list-style-type: none;

}

.sidebar a {
    display: block;
    color: black;
    padding: 12px;
    text-decoration: none;
}

.sidebar a.active {
    /*background-color: #007bff;*/
    /*color: white;*/
}

.sidebar a:hover:not(.active) {
    background-color: #555;
    color: white;
}

div.content {
    /*margin-left: 200px;*/
    /*padding: 1px 16px;*/
    /*height: 1000px;*/
}
a.active {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    font-weight: bolder;
}
@media screen and (max-width: 700px) {
    .sidebar {
        width: 100%;
        height: auto;
        position: relative;
    }
    .sidebar a {float: left;}
    div.content {margin-left: 0;}
}

@media screen and (max-width: 400px) {
    .sidebar a {
        text-align: center;
        float: none;
    }
}
</style>
